import { styled } from 'twin.macro';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Heading, Button } from '../atoms';

const Container = styled.div`
  background: url('/images/hero-bg.jpg');
  background-size: cover;
  background-position: center;
`;
const Hero = () => (
  <Container>
    <div tw="container mx-auto px-4 md:py-24 py-16">
      <div tw="grid md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-6 items-center">
        <div tw="w-full">
          <Fade left>
            <Heading extraLarge fontNormal>
              <span tw="text-green-400">Empower</span> your field resources with
              Pro
              <span tw="text-secondary">Field</span>
            </Heading>
            <div tw="mt-6">
              <Link to="/request-quote">
                <Button>Request For Demo</Button>
              </Link>
            </div>
          </Fade>
        </div>
        <div tw="w-full my-1 rounded-lg overflow-hidden">
          <Fade right>
            <StaticImage
              src="../../../static/images/profield-hero.png"
              alt="startso"
              placeholder="blurred"
            />
          </Fade>
        </div>
      </div>
    </div>
  </Container>
);
export default Hero;
