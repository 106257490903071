/* eslint-disable jsx-a11y/media-has-caption */
import { styled } from 'twin.macro';
import React from 'react';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import config from '../utils/config';
import Header from '../components/Header';
import Hero from '../components/home/Hero';
import Testimonial from '../components/service/Testimonial';
import Features from '../components/home/Features';
import CartFeatures from '../components/home/CartFeatures';
import RequestDemo from '../components/home/RequestDemo';
import TestimonialBottom from '../components/home/Testimonial';
// import Blogs from '../components/Blogs';

const Container = styled.div``;

const IndexPage = () => (
  <Layout hideHeader>
    <Seo
      title="A QRCODE based smart CRM solution"
      description={config.description}
      url={config.siteUrl}
      image={config.logo}
    />
    <Container>
      <Header />
      <Hero />
      <Testimonial isHomePage />
      <CartFeatures />
      <Features />
      <RequestDemo />
      <TestimonialBottom />
    </Container>
  </Layout>
);
export default IndexPage;
