import { styled } from 'twin.macro';
import React from 'react';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';

import { Button, Heading } from '../atoms';
import Column from '../product/Column';

const Container = styled.div`
  background-image: url('/images/product-banner.jpg');
  background-position: center;
  .bg-blur {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const RequestDemo = () => (
  <Container tw="mb-20">
    <div className="bg-blur">
      <div tw="container px-4 mx-auto py-10">
        <Column
          isGap="gap-12"
          secondColumn={
            <Fade right>
              <div tw="py-5">
                <Heading hasTextWhite extraLarge>
                  Reach out for Demo and pricing{' '}
                </Heading>
                <div tw="mt-5">
                  <Link to="/request-quote">
                    <Button>Request for demo</Button>
                  </Link>
                </div>
              </div>
            </Fade>
          }
          firstColumn={
            <Fade left>
              <div tw="py-8">
                <img src="/images/upanal/hero.png" alt="profield" />
              </div>
            </Fade>
          }
        />
      </div>
    </div>
  </Container>
);
export default RequestDemo;
