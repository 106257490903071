import { styled } from 'twin.macro';
import React from 'react';
import Fade from 'react-reveal/Fade';
import { Subtitle, Heading, Paragraph } from '../atoms';

const Container = styled.div`
  background: rgba(0, 0, 0, 0) url('/images/bg-pattern.png');
`;

const ImageWrapper = styled.div`
  background: ${(props) => props.bgColor};
`;
const features = [
  {
    id: 1,
    image: '/images/upanal/admin-1.png',
    color: '#dcebfb',
    blackHeading: 'Start',
    redHeading: 'So',
    title: ' - Admin Dashboard',
    description:
      'A web application platform for businesses to manage field resources, customer management, resource management and daily track of work with simple & smart workflows, Interactive analytic insights & reports.',
  },
  {
    id: 2,
    image: '/images/upanal/app-1.png',
    color: '#fce4e6',
    blackHeading: 'Pro',
    redHeading: 'Field',
    title: ' - for field resources',
    description:
      'A mobile app for field resources – to perform & manage daily activities like Sales/service/AMC/Breakdown activities with smart workflows, Attendance management, claim/expense management. Get critical alerts, push notifications, whatsapp & telegram notifications.',
  },
  {
    id: 3,
    image: '/images/upanal/startscan.png',
    color: '#dbf8e1',
    blackHeading: 'Start',
    redHeading: 'Scan',
    title: ' - for end users',
    description:
      'A mobile app for end users- register and raise service/AMC/Breakdown requests, get real time progress notification on the request, view history of all the requests, view & buy/upgrade products with exchange benefits.',
  },
  {
    id: 4,
    image: '/images/upanal/mstartscan.png',
    color: '#dbddf8',
    blackHeading: 'mStart',
    redHeading: 'Scan',
    title: ' - for users without app',
    description:
      'A QRCode based web solution for end users to raise service/AMC/Breakdown requests, get real time progress notification on the request',
  },
];
const CartFeatures = () => (
  <Container tw="py-24">
    <div tw="mx-auto max-w-4xl px-4 pb-4">
      <div tw="text-center">
        <Heading fontMedium>Product Components</Heading>
      </div>
      <div tw="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-6 mt-20 justify-center">
        {features.map((item, i) => (
          <Fade
            left={(i + 1) % 2 !== 0}
            right={(i + 1) % 2 === 0}
            key={item.id}
          >
            <div
              key={item.id}
              tw="bg-white shadow-3xl rounded-lg overflow-hidden h-full"
            >
              <ImageWrapper tw="px-4 py-2" bgColor={item.color}>
                <img
                  src={item.image}
                  alt={item.title}
                  tw="w-auto max-h-72 object-contain md:h-72 h-64 mx-auto"
                />
              </ImageWrapper>
              <div tw="px-6 py-5 bg-white">
                <Subtitle>
                  {item.blackHeading}
                  <span tw="text-secondary">{item.redHeading}</span>
                  {item.title}
                </Subtitle>
                <Paragraph>{item.description}</Paragraph>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </div>
  </Container>
);
export default CartFeatures;
