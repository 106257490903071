import React from 'react';
import 'twin.macro';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slide from 'react-reveal/Fade';

import { Heading, Paragraph, Subtitle } from '../atoms';

const testimonial = [
  {
    id: 1,
    company: 'Upanal CNC Solutions',
    image: '/images/upanal-logo.png',
    bio: 'M/s.UPANAL CNC SOLUTIONS has been started with a promise that "products supplied by us will provide a greater value than the price paid for it & service is the part of product',
    comment:
      'The technology adoption in sales and service industry through ProField truly solved the challenge of monitoring field resources and daily workflows and many more.',
    username: 'Arun Upanal',
    userRole: 'Director of operations',
  },
  {
    id: 2,
    company: 'SquarenineInfra',
    image: '/images/olive-adobe-logo.png',
    bio: 'SqaureNineInfra is a pioneer in the real estate industry in Mysuru and offers various investment projects and with 1percentage.in we also provide Buy, Sell & Rent portal exclusively in Mysore.',
    comment:
      'Technology in real estate sales & rental business is a great step we took with ProField and it solved all our day to day challenges, increased the productivity, managing the field resources and it enhanced the way of doing the business with ease .',
    username: 'Pramod SP',
    userRole: 'Proprietor',
  },
];
const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    arrows: false,
    // fade: true,
    // autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <div tw="mx-auto my-28 container px-6">
      <div tw="text-center">
        <Heading>Testimonials</Heading>
      </div>
      <div tw="mt-16 max-w-screen-md mx-auto">
        <Slider {...settings}>
          {testimonial.map((item) => (
            <div key={item.id}>
              <Slide bottom>
                <div tw="text-center">
                  <img src={item.image} alt="user" tw=" h-20 mb-4 mx-auto" />
                  <Subtitle fontNormal>{item.bio}</Subtitle>
                  <div tw="px-6 pt-3 mx-auto max-w-xl">
                    <Paragraph>“{item.comment}”</Paragraph>
                  </div>
                  <p tw="text-secondary font-semibold text-xl mt-5">
                    {item.username}
                  </p>
                  <p tw="text-sm text-tertiary">
                    {`${item.userRole}, ${item.company}`}
                  </p>
                </div>
              </Slide>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default Testimonial;
