import { styled } from 'twin.macro';
import React from 'react';
import Fade from 'react-reveal/Fade';
import { Subtitle, Heading } from '../atoms';

const Container = styled.div`
  /* background: rgba(0, 0, 0, 0) url('/images/bg-pattern.png'); */
`;

const features = [
  {
    id: 1,
    icon: '/featureIcons/empower.png',
    title: 'Empower field resources with technology',
  },
  {
    id: 2,
    icon: '/featureIcons/tasks.png',
    title: 'Assign tasks and track field resources',
  },
  {
    id: 3,
    icon: '/featureIcons/employee-management.png',
    title: 'Employee Management',
  },
  {
    id: 4,
    icon: '/featureIcons/manage-machine.png',
    title: 'Managing Machines data',
  },
  {
    id: 5,
    icon: '/featureIcons/manage-property.png',
    title: 'Manage property data',
  },

  {
    id: 8,
    icon: '/featureIcons/automated.png',
    title: 'Automated sales/service request creation',
  },
  {
    id: 9,
    icon: '/featureIcons/claim.png',
    title: 'Claims/Expense Management',
  },
  {
    id: 10,
    icon: '/featureIcons/attendance.png',
    title: 'Attendance management',
  },
  {
    id: 11,
    icon: '/featureIcons/inventory.png',
    title: 'Inventory/Spares management',
  },
  {
    id: 13,
    icon: '/featureIcons/integration.png',
    title: 'Easy Integration',
  },
  {
    id: 14,
    icon: '/featureIcons/workflow.png',
    title: 'Customizable workflows',
  },

  {
    id: 16,
    icon: '/featureIcons/location-tracor.png',
    title: 'Smart location tracker',
  },
  {
    id: 17,
    icon: '/featureIcons/qr-code.png',
    title: 'QRCode based solution for end user',
  },
  {
    id: 18,
    icon: '/featureIcons/analytics.png',
    title: 'Analytics and report',
  },
  {
    id: 20,
    icon: '/featureIcons/sign-off.png',
    title: 'Digital Sign off',
  },
  {
    id: 21,
    icon: '/featureIcons/cloud.png',
    title: 'Cloud based infrastructure',
  },
  {
    id: 22,
    icon: '/featureIcons/picture.png',
    title: 'Multimedia Media capture-Image/audio',
  },
  {
    id: 12,
    icon: '/featureIcons/management.png',
    title: 'Sales/Service/Breakdown/AMC Management',
  },
  {
    id: 6,
    icon: '/featureIcons/qr-code-slip.png',
    title: 'Generate QRCODE for machines for raising breakdown/service request',
  },
  {
    id: 7,
    icon: '/featureIcons/qr-code-slip.png',
    title:
      'Generate QRCODE for properties to get interests from prospective buyers',
  },
  {
    id: 24,
    icon: '/featureIcons/message.png',
    title: 'Customers can also raise sales/service request with just an SMS*',
  },
  {
    id: 23,
    icon: '/featureIcons/notification.png',
    title:
      'Real time notifications- in app Push notifications, email, WhatsApp/telegram and critical alerts',
  },

  {
    id: 15,
    icon: '/featureIcons/user-assign.png',
    title:
      'AI/ML model to auto assign the service/sales task to field resources near to customer location/availability –geofencing*',
  },
];
const Features = () => (
  <Container tw="pt-24 pb-32">
    <div tw="mx-auto max-w-screen-lg px-4">
      <div tw="mx-auto max-w-screen-lg text-center">
        <Heading>More features...</Heading>
      </div>
      <div tw="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:gap-8 gap-6 mt-16">
        {features.map((item) => (
          <div
            key={item.id}
            tw="flex space-x-4 max-w-md transition transform motion-reduce:transition-none motion-reduce:transform-none"
          >
            <Fade bottom delay={100}>
              <img src={item.icon} alt="icon" tw="h-7 w-7" />
              <div>
                <Subtitle fontNormal>{item.title}</Subtitle>
              </div>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  </Container>
);
export default Features;
